import Layout from '@/views/layout/Index.vue'

export default [
  {
    path: '/',
    name: 'judge',
    meta: { keepAlive: true },
    component: () => import('@/views/Judge.vue'),
  },
  {
    path: '/',
    name: 'index',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/norm-menu',
        name: 'norm-menu',
        component: () => import('@/views/NormMenu.vue'),
      },
      {
        path: '/calc-card',
        name: 'calc-card',
        component: () => import('@/views/CalcCard.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/User.vue'),
      },
    ],
  },
  {
    path: '/user-info',
    name: 'user-info',
    component: () => import('@/views/UserInfo.vue'),
  },
  {
    path: '/sub-menu/:parentId/:parentName',
    name: 'sub-menu',
    component: () => import('@/views/SubMenu.vue'),
  },
  {
    path: '/question/:menuId/:menuName',
    name: 'question',
    component: () => import('@/views/Question.vue'),
  },
  {
    path: '/result/:menuId/:menuName/:answerId?',
    name: 'result',
    component: () => import('@/views/Result.vue'),
  },
  {
    path: '/my-answer',
    name: 'my-answer',
    component: () => import('@/views/MyAnswer.vue'),
  },
  {
    path: '/my-result',
    name: 'my-result',
    component: () => import('@/views/MyResult.vue'),
  },
  {
    path: '/norm-title/:normMenuId/:normMenuName',
    name: 'norm-title',
    meta: { keepAlive: true },
    component: () => import('@/views/NormTitle.vue'),
  },
  {
    path: '/norm/:id/:keyword?',
    name: 'norm',
    component: () => import('@/views/Norm.vue'),
  },
  {
    path: '/judge-result',
    name: 'judge-result',
    component: () => import('@/views/JudgeResult.vue'),
  },
  {
    path: '/description',
    name: 'description',
    component: () => import('@/views/Description.vue'),
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: () => import('@/views/Upgrade.vue'),
  },
  {
    path: '/calc/:id',
    name: 'calc',
    component: () => import('@/views/Calc.vue'),
  },
]
