// 基准大小
const baseSize = 100

// 设置 rem 函数
function setRem() {

  const getWidth = document.documentElement.clientWidth // 获取屏幕的宽度
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  let scale = scale = document.documentElement.clientWidth / 750
  if (getWidth > 420 && getWidth <= 1024) { // 平板
    scale = document.documentElement.clientWidth / 1500
  } else if (getWidth > 1024) {
    scale = document.documentElement.clientWidth / 2000
  }
  console.log('--------------------', getWidth, scale)
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}

// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem()
  window.location.reload()
}
