import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import { Toast } from 'mand-mobile'

/* 创建axios实例 */
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 0, // 请求超时时间
})

/* request拦截器 */
service.interceptors.request.use(
  config => {
    Toast.loading('加载中...')
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    const token = Vue.ls.get('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    Toast.hide()
    Promise.reject(error)
  },
)

/* respone拦截器 */
service.interceptors.response.use(
  response => {
    Toast.hide()
    // 获取返回数据，并处理。
    return response.data
  },
  error => {
    const { response } = error
    if (response) {
      const token = Vue.ls.get('token')

      if (response.status === 403) {
        Toast.info('访问受限')
      } else if (response.status === 401) {
        Toast.info('授权过期，重新登录')
        if (token) { //退出登录
          store.dispatch('user/Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          })
        }
      } else if (response.status === 422) {
        Toast.info(response.data.message)
      } else if (response.status >= 400) {
        Toast.info('发生错误，请重试')
        if (token && response.data.message === 'Unauthenticated.') {
          store.dispatch('user/Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          })
        }
      }
    }

    return Promise.reject(error)
  },
)

export default service
